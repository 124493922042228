/* eslint-disable object-curly-newline */
export const logTypes = [
  { id: 1, slug: 'keyboard', name: 'Клавиатура', category: 'events' },
  { id: 2, slug: 'screenshots', name: 'Скриншоты', category: 'events' },
  { id: 3, slug: 'programs', name: 'Программы', category: 'events' },
  { id: 4, slug: 'files', name: 'Файлы', category: 'events' },
  { id: 5, slug: 'clipboard', name: 'Буфер обмена', category: 'events' },
  { id: 6, slug: 'printer', name: 'Принтер', category: 'devices' },
  { id: 7, slug: 'installed-programs', name: 'Установленные программы', category: 'events' },
  { id: 8, slug: 'external-drives', name: 'Внешние накопители', category: 'devices' },
  { id: 10, slug: 'visited-sites', name: 'Посещенные сайты', category: 'internet' },
  { id: 11, slug: 'computer', name: 'Компьютер', category: 'events' },
  { id: 14, slug: 'shadow-copyings', name: 'Теневое копирование', category: 'devices' },
  { id: 15, slug: 'email-clients', name: 'Почта', category: 'internet' },
  { id: 18, slug: 'network-accesses', name: 'Сеть:доступ', category: 'events' },
  { id: 19, slug: 'network-files', name: 'Сеть:файлы', category: 'events' },
  { id: 20, slug: 'web-mail', name: 'Web почта', category: 'internet' },
  { id: 21, slug: 'file-upload', name: 'Выгрузка файлов', category: 'internet' },
  { id: 22, slug: 'social-networks', name: 'Социальные сети', category: 'internet' },
  { id: 23, slug: 'messengers', name: 'Мессенджеры', category: 'internet' },
  { id: 24, slug: 'webcams-and-microphones', name: 'WebCam/Microphone', category: 'devices' },
  { id: 25, slug: 'hardware-changes', name: 'Изменения оборудования', category: 'devices' },
  { id: 26, slug: 'search-queries', name: 'Поисковые запросы', category: 'internet' },
  { id: 27, slug: 'mic-records', name: 'Записи с микрофона', category: 'devices' },
];
