import axios from '../axios';

/**
 * @returns {Promise<{ role: number; allowedTypes: number[]; }>}
 */
export function getInfo() {
  return axios.get('/user/info').then(({ data: { id, role, allowedTypes, accessMap, buildVersion } }) => (
    { id, role, allowedTypes, accessMap, buildVersion }));
}

/**
 * @param {string} username
 * @param {string} password
 * @returns {Promise<{ role: number; allowedTypes: number[]; }>}
 */
export function login(username, password, authCode) {
  return axios.post('/user/login', { login: username, password, authCode }).then((
    { data: { id, role, allowedTypes, accessMap, tgCodeNeeded } },
  ) => ({ id, role, allowedTypes, accessMap, tgCodeNeeded }));
}

export function logout() {
  return axios.get('/user/logout');
}
