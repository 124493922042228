export default {
  namespaced: true,
  state: ({
    isLoaderNeeded: false,
  }),
  getters: {
    isLoaderNeeded: (state) => state.isLoaderNeeded,
  },
  mutations: {
    setIsLoaderNeeded: (state, payload) => {
      state.isLoaderNeeded = payload;
    },
  },
};
