import Vue from 'vue';
import { Promised } from 'vue-promised';
import App from './application.vue';
import router from './router';
import store from './store';
import './register-service-worker';

import 'reset-css';
import '@/assets/styles/index.css';
import '@/assets/styles/transitions.css';
import 'vue2-datepicker/index.css';

Vue.component('promised', Promised);

Vue.config.productionTip = false;

store.dispatch('init').finally(() => {
  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount('#app');
});
