export default {
  namespaced: true,
  state: ({
    dateFrom: '',
    dateTo: '',
    nameComputer: '',
    ipComputer: '',
    macComputer: '',
    screenshots: [],
  }),
  mutations: {
    dateFrom: (state, payload) => { state.dateFrom = payload; },
    dateTo: (state, payload) => { state.dateTo = payload; },
    nameComputer: (state, payload) => { state.nameComputer = payload; },
    ipComputer: (state, payload) => { state.ipComputer = payload; },
    macComputer: (state, payload) => { state.macComputer = payload; },
    screenshots: (state, payload) => { state.screenshots = payload; },
  },
};
