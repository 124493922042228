import programs from './programs';
import visitedSites from './visited-sites';

export default {
  namespaced: true,
  modules: {
    programs,
    visitedSites,
  },
};
