export default {
  namespaced: true,
  state: ({
    newNotifications: false,
  }),
  getters: {
    newNotifications: (state) => state.newNotifications,
  },
  mutations: {
    setNewNotifications: (state, payload) => {
      state.newNotifications = payload;
    },
  },
};
