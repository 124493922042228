function toggleNodeChecked(node, id, isTargetNodeChild, isParentChecked, parentList) {
  if ((node.id.toString().concat(node.type) === id)) {
    node.checked = !node.checked;
    isTargetNodeChild = true;
    isParentChecked = node.checked;
    if (!node.checked) {
      parentList.forEach((parent) => { parent.checked = false; });
    } else {
      const reverseList = parentList.slice().reverse();
      for (let i = 0; i < reverseList.length; i++) {
        if (!reverseList[i].checked) {
          let allChecked = true;
          reverseList[i].children.forEach((child) => {
            if (!child.checked) {
              allChecked = false;
            }
          });
          if (allChecked) {
            reverseList[i].checked = true;
          } else break;
        }
      }
    }
  }
  if (isTargetNodeChild) {
    node.checked = isParentChecked;
  }
  if (node.type === 'folder') {
    parentList.push(node);
    if (node.children) {
      node.children.forEach((item) => {
        toggleNodeChecked(item, id, isTargetNodeChild, isParentChecked, parentList.slice());
      });
    }
  }
}

function checkAll(node, isAllChecked) {
  if (node.checked !== isAllChecked) {
    node.checked = isAllChecked;
    if (node.type === 'folder') {
      node.children.forEach((child) => {
        checkAll(child, isAllChecked);
      });
    }
  }
}

function getComputerId(node, checkedComputers) {
  if (node.type !== 'folder') {
    if (node.checked === true) {
      checkedComputers.push(node.id);
    }
  } else {
    node.children.forEach((child) => getComputerId(child, checkedComputers));
  }
}

export default {
  namespaced: true,
  state: ({
    // reports/printers/statistics/print-stat
    printStatSortState: true,
    computersTree: [],
    checkedComputerId: null,
  }),
  getters: {
    printStatSortState: (state) => state.printStatSortState,
    checkedComputerId: (state) => state.checkedComputerId,
    computersTree: (state) => state.computersTree,
    checkedComputersList: (state) => {
      const checkedComputers = [];
      state.computersTree.forEach((node) => {
        getComputerId(node, checkedComputers);
      });
      return checkedComputers;
    },
  },
  mutations: {
    togglePrintStatSortState: (state) => {
      state.printStatSortState = !state.printStatSortState;
    },
    setComputerTree: (state, payload) => {
      state.computersTree = payload;
    },
    checkOneComputer: (state, payload) => {
      state.checkedComputerId = payload;
    },
    toggleComputerNodeChecked: (state, payload) => {
      state.computersTree.forEach((element) => {
        toggleNodeChecked(element, payload, false, false, []);
      });
      state.computersTree = JSON.parse(JSON.stringify(state.computersTree));
    },
    checkAllComputerNodes: (state, payload) => {
      state.computersTree.forEach((element) => {
        checkAll(element, payload);
      });
      state.computersTree = JSON.parse(JSON.stringify(state.computersTree));
    },
  },
};
