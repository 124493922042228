export default {
  namespaced: true,
  state: ({
    idleSettings: {},
    logSettings: {},
    commonSettings: {},
    screenshotSettings: {},
    shadowSettings: {},
    fileSettings: {},
    indexSettings: {},
    driveSettings: {},
    inetSettings: {},
    webcamSettings: {},
    notificationSettings: {},
    profile: undefined,
    prodProfile: undefined,
    wtProfile: undefined,
    security: { checked: [], unchecked: [] },
    acsel: { checked: [], unchecked: [] },
  }),
  getters: {
    idleSettings: (state) => state.idleSettings,
    logSettings: (state) => state.logSettings,
    commonSettings: (state) => state.commonSettings,
    screenshotSettings: (state) => state.screenshotSettings,
    shadowSettings: (state) => state.shadowSettings,
    fileSettings: (state) => state.fileSettings,
    indexSettings: (state) => state.indexSettings,
    driveSettings: (state) => state.driveSettings,
    inetSettings: (state) => state.inetSettings,
    webcamSettings: (state) => state.webcamSettings,
    notificationSettings: (state) => state.notificationSettings,
    prodProfile: (state) => state.prodProfile,
    wtProfile: (state) => state.wtProfile,
    security: (state) => state.security,
    acsel: (state) => state.acsel,
    notEmpty: (state) => {
      if (
        Object.keys(state.idleSettings).length > 0
        || Object.keys(state.logSettings).length > 0
        || Object.keys(state.commonSettings).length > 0
        || Object.keys(state.screenshotSettings).length > 0
        || Object.keys(state.shadowSettings).length > 0
        || Object.keys(state.fileSettings).length > 0
        || Object.keys(state.indexSettings).length > 0
        || Object.keys(state.driveSettings).length > 0
        || Object.keys(state.inetSettings).length > 0
        || Object.keys(state.webcamSettings).length > 0
        || Object.keys(state.notificationSettings).length > 0
        || state.security.checked.length > 0
        || state.security.unchecked.length > 0
        || state.acsel.checked.length > 0
        || state.acsel.unchecked.length > 0
        || state.prodProfile || state.wtProfile) return true;
      return false;
    },
    profile: (state) => state.profile,
  },
  actions: {
    // eslint-disable-next-line
    refresh({ commit, dispatch }) {
    },
    // eslint-disable-next-line
    cancel({ commit, dispatch }) {
    },
    // eslint-disable-next-line
    paramChanged({ commit, dispatch }) {
    },
  },
  mutations: {
    addIdleSettings: (state, payload) => {
      const newSettings = { ...state.idleSettings };
      newSettings[payload.key] = payload.value;
      state.idleSettings = newSettings;
    },
    addLogSettings: (state, payload) => {
      const newSettings = { ...state.logSettings };
      newSettings[payload.key] = payload.value;
      state.logSettings = newSettings;
    },
    addCommonSettings: (state, payload) => {
      const newSettings = { ...state.commonSettings };
      newSettings[payload.key] = payload.value;
      state.commonSettings = newSettings;
    },
    addScreenshotSettings: (state, payload) => {
      const newSettings = { ...state.screenshotSettings };
      newSettings[payload.key] = payload.value;
      state.screenshotSettings = newSettings;
    },
    addShadowSettings: (state, payload) => {
      const newSettings = { ...state.shadowSettings };
      newSettings[payload.key] = payload.value;
      state.shadowSettings = newSettings;
    },
    addFileSettings: (state, payload) => {
      const newSettings = { ...state.fileSettings };
      newSettings[payload.key] = payload.value;
      state.fileSettings = newSettings;
    },
    addIndexSettings: (state, payload) => {
      const newSettings = { ...state.indexSettings };
      newSettings[payload.key] = payload.value;
      state.indexSettings = newSettings;
    },
    addDriveSettings: (state, payload) => {
      const newSettings = { ...state.driveSettings };
      newSettings[payload.key] = payload.value;
      state.driveSettings = newSettings;
    },
    addInetSettings: (state, payload) => {
      const newSettings = { ...state.inetSettings };
      newSettings[payload.key] = payload.value;
      state.inetSettings = newSettings;
    },
    addWebcamSettings: (state, payload) => {
      const newSettings = { ...state.webcamSettings };
      newSettings[payload.key] = payload.value;
      state.webcamSettings = newSettings;
    },
    addNotificationSettings: (state, payload) => {
      const newSettings = { ...state.notificationSettings };
      newSettings[payload.key] = payload.value;
      state.notificationSettings = newSettings;
    },
    addVideoSettings: (state, payload) => {
      const newSettings = { ...state.webcamSettings.Video };
      newSettings[payload.key] = payload.value;
      const result = { ...state.webcamSettings };
      result.Video = newSettings;
      state.webcamSettings = result;
    },
    addAudioSettings: (state, payload) => {
      const newSettings = { ...state.webcamSettings.Audio };
      newSettings[payload.key] = payload.value;
      const result = { ...state.webcamSettings };
      result.Audio = newSettings;
      state.webcamSettings = result;
    },
    addScreenSettings: (state, payload) => {
      const newSettings = { ...state.webcamSettings.Screen };
      newSettings[payload.key] = payload.value;
      const result = { ...state.webcamSettings };
      result.Screen = newSettings;
      state.webcamSettings = result;
    },
    addSecurity: (state, payload) => {
      const newSettings = { ...state.security };
      if (payload.checked) {
        const indexOfObject = newSettings.unchecked.findIndex((object) => object.ruleId === payload.ruleId);
        if (indexOfObject > -1) newSettings.unchecked.splice(indexOfObject, 1);
        else newSettings.checked.push(payload);
      } else {
        const indexOfObject = newSettings.checked.findIndex((object) => object.ruleId === payload.ruleId);
        if (indexOfObject > -1) newSettings.checked.splice(indexOfObject, 1);
        else newSettings.unchecked.push(payload);
      }
      state.security = newSettings;
    },
    addAcsel: (state, payload) => {
      const newSettings = { ...state.acsel };
      if (payload.checked) {
        const indexOfObject = newSettings.unchecked.findIndex((object) => object.stype === payload.stype);
        if (indexOfObject > -1) newSettings.unchecked.splice(indexOfObject, 1);
        else {
          const indexOfObject2 = newSettings.checked.findIndex((object) => object.stype === payload.stype);
          if (indexOfObject2 > -1) newSettings.checked.splice(indexOfObject2, 1);
        }
        newSettings.checked.push(payload);
      } else {
        const indexOfObject = newSettings.checked.findIndex((object) => object.stype === payload.stype);
        if (indexOfObject > -1) newSettings.checked.splice(indexOfObject, 1);
        newSettings.unchecked.push(payload);
      }
      state.acsel = newSettings;
    },
    clearChangedSettings: (state) => {
      state.idleSettings = {};
      state.logSettings = {};
      state.commonSettings = {};
      state.screenshotSettings = {};
      state.shadowSettings = {};
      state.fileSettings = {};
      state.indexSettings = {};
      state.driveSettings = {};
      state.inetSettings = {};
      state.webcamSettings = {};
      state.notificationSettings = {};
      state.prodProfile = undefined;
      state.wtProfile = undefined;
      state.security = { checked: [], unchecked: [] };
      state.acsel = { checked: [], unchecked: [] };
    },
    setProfile: (state, payload) => {
      state.profile = payload;
    },
    setProdProfile: (state, payload) => {
      state.prodProfile = payload;
    },
    setWtProfile: (state, payload) => {
      state.wtProfile = payload;
    },
  },
};
