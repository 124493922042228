export default {
  namespaced: true,
  state: () => ({
    categories: [],
  }),
  mutations: {
    setCategories: (state, payload) => {
      state.categories = payload;
    },
  },
};
