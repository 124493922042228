export default {
  namespaced: true,
  state: ({
    worktimeDetails: {},
    heatMap: {},
    disciplineViolations: {},
    productivity: {},
    activityFeed: {},
    microphoneDetails: {},
    programs: {},
    websites: {},
    detailsMessengers: {},
    docStat: {},
    printerReport: {},
    employeeReport: {},
    consolidated: {},
    appsTime: {},
    mail: {},
    messengers: {},
    microphoneSum: {},
    userStat: {},
    printStat: {},
    logs: [],
  }),
  getters: {
    worktimeDetails: (state) => state.worktimeDetails,
    heatMap: (state) => state.heatMap,
    disciplineViolations: (state) => state.disciplineViolations,
    productivity: (state) => state.productivity,
    activityFeed: (state) => state.activityFeed,
    microphoneDetails: (state) => state.microphoneDetails,
    programs: (state) => state.programs,
    websites: (state) => state.websites,
    detailsMessengers: (state) => state.detailsMessengers,
    docStat: (state) => state.docStat,
    printerReport: (state) => state.printerReport,
    employeeReport: (state) => state.employeeReport,
    consolidated: (state) => state.consolidated,
    appsTime: (state) => state.appsTime,
    mail: (state) => state.mail,
    messengers: (state) => state.messengers,
    microphoneSum: (state) => state.microphoneSum,
    userStat: (state) => state.userStat,
    printStat: (state) => state.printStat,
    logs: (state) => state.logs,
  },
  mutations: {
    setWorktime: (state, payload) => {
      state.worktimeDetails = payload;
    },
    setHeatMap: (state, payload) => {
      state.heatMap = payload;
    },
    setDisciplineViolations: (state, payload) => {
      state.disciplineViolations = payload;
    },
    setProductivity: (state, payload) => {
      state.productivity = payload;
    },
    setActivityFeed: (state, payload) => {
      state.activityFeed = payload;
    },
    setMicrophoneDetails: (state, payload) => {
      state.activityFeed = payload;
    },
    setPrograms: (state, payload) => {
      state.programs = payload;
    },
    setWebsites: (state, payload) => {
      state.websites = payload;
    },
    setDetailsMessengers: (state, payload) => {
      state.detailsMessengers = payload;
    },
    setDocStat: (state, payload) => {
      state.docStat = payload;
    },
    setPrinterReport: (state, payload) => {
      state.printerReport = payload;
    },
    setEmployeeReport: (state, payload) => {
      state.employeeReport = payload;
    },
    setConsolidated: (state, payload) => {
      state.consolidated = payload;
    },
    setAppsTime: (state, payload) => {
      state.appsTime = payload;
    },
    setInternetTable: (state, payload) => {
      state[payload.type] = payload.data;
    },
    setUserStat: (state, payload) => {
      state.userStat = payload;
    },
    setPrintStat: (state, payload) => {
      state.printStat = payload;
    },
    setLogs: (state, payload) => {
      state.logs = payload;
    },
  },
};
