export default {
  namespaced: true,
  state: ({
    newComputer: {},
  }),
  getters: {
    newComputer: (state) => state.newComputer,
  },
  mutations: {
    addComputer: (state, payload) => {
      state.newComputer = payload;
    },
  },
};
