import Vue from 'vue';
import VueToast from 'vue-toast-notification';
import axios from '@/api/axios';
import 'vue-toast-notification/dist/theme-default.css';

Vue.use(VueToast);

function openToast(message, type) {
  Vue.$toast.open({
    message,
    type,
  });
}

export function getTasks() {
  return axios.post('/tasks').then((response) => {
    if (response.data.success) {
      return response.data.result;
    }
    return 'error';
  });
}

export function addTask(type, computers, messages) {
  return axios.post(`/tasks/addTask/?type=${type}`, { computers, messages }).then((response) => {
    if (response.data.success) {
      openToast('Задача успешно добавлена', 'success');
      return 'success';
    }
    openToast(response.data.message, 'error');
    return 'error';
  });
}

export function readList(id, type) {
  return axios.post(`/tasks/readList/?type=${type}&id=${id}`).then((response) => {
    if (response.data.success) {
      return response.data.result;
    }
    return 'error';
  });
}

export function getNewTasks() {
  return axios.post('/tasks/new').then((response) => {
    if (response.data.success) {
      return response.data.result;
    }
    return 'error';
  });
}

export function changeTaskStage(id) {
  return axios.post(`/tasks/changeStage/?id=${id}`).then((response) => {
    if (response.data.success) {
      return response.data.result;
    }
    return 'error';
  });
}
