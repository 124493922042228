const isLastId = (idUser, logTypeSlug, idComputer) => (i) => i.idUser === idUser
  && i.logTypeSlug === logTypeSlug
  && i.idComputer === idComputer;

export default {
  namespaced: true,
  state: () => ({
    lastId: [],
  }),
  getters: {
    getLastId: (state, getters, rootState) => (logTypeSlug, idComputer) => {
      const idUser = rootState.user.id;
      const lastId = state.lastId.find(isLastId(idUser, logTypeSlug, idComputer));

      return lastId ? lastId.id : 0;
    },
  },
  mutations: {
    setLastId: (state, { id, idComputer, idUser, logTypeSlug }) => {
      const existIndex = state.lastId.findIndex(isLastId(idUser, logTypeSlug, idComputer));

      if (existIndex !== -1) {
        state.lastId[existIndex].id = id;
      } else {
        state.lastId.push({ id, idComputer, idUser, logTypeSlug });
      }
    },
  },
  actions: {
    setLastId: ({ commit, rootState }, payload) => {
      const idUser = rootState.user.id;
      commit('setLastId', { idUser, ...payload });
    },
  },
};
