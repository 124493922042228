export default function loadQueryParametersFromStore({ to, next, store }) {
  const storeDates = store.getters['filters/dateRange'];

  if (to.query.selectedComputerId) {
    store.commit('pageSpecificData/checkOneComputer', to.query.selectedComputerId);
  }

  if (to.meta.requiredStoreParameters.worktimeOnly) {
    if (to.query.isWorktimeOnly) {
      store.commit('filters/setIsWorktimeOnly', to.query.isWorktimeOnly.toLowerCase() === 'true');
    }
  }

  if (to.meta.requiredStoreParameters.dateRange) {
    if (to.query.dateFrom && to.query.dateTo) {
      store.commit('filters/updateDateRange', [to.query.dateFrom, to.query.dateTo]);
      return next();
    }

    const queryMutations = {};

    if (!to.query.dateFrom) {
      queryMutations.dateFrom = storeDates[0];
    } else {
      queryMutations.dateFrom = to.query.dateFrom;
      store.commit('filters/updateDateFrom', queryMutations.dateFrom);
    }

    if (!to.query.dateTo) {
      queryMutations.dateTo = storeDates[1];
    } else {
      queryMutations.dateTo = to.query.dateTo;
      store.commit('filters/updateDateTo', queryMutations.dateTo);
    }

    return next({
      path: to.path,
      query: {
        dateFrom: queryMutations.dateFrom,
        dateTo: queryMutations.dateTo,
      },
    });
  }

  return next();
}
