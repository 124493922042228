import dayjs from 'dayjs';

export default {
  namespaced: true,
  state: ({
    dateRange: [dayjs().format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')],
    selects: {
      printer: {
        options: [],
        selectedOption: '',
      },
      computer: {
        options: [],
        selectedOption: '',
      },
    },
    isTableGroupedByDates: true,
    isWorktimeOnly: false,
    selectedApps: [],
    switcher: 1,
    dateSelect: 3,
  }),
  getters: {
    dateSelect: ((state) => state.dateSelect),
    dateRange: ((state) => state.dateRange),
    formattedDateRange: ((state, getters) => ({
      dateFrom: dayjs(getters.dateRange[0])
        .set('hour', 0)
        .set('minute', 0)
        .set('second', 0)
        .set('millisecond', 0),
      dateTo: dayjs(getters.dateRange[1])
        .set('hour', 23)
        .set('minute', 59)
        .set('second', 59)
        .set('millisecond', 0),
    })),
    printerSelectedOption: (state) => state.selects.printer.selectedOption,
    computerSelectedOption: (state) => state.selects.computer.selectedOption,
    isTableGroupedByDates: (state) => state.isTableGroupedByDates,
    isWorktimeOnly: (state) => state.isWorktimeOnly,
    selectedApps: (state) => state.selectedApps,
    switcher: (state) => state.switcher,
  },
  mutations: {
    setDateSelect: (state, payload) => {
      state.dateSelect = payload;

      switch (state.dateSelect) {
        case 0: {
          const curr = new Date();
          const first = curr.getDate() - curr.getDay() + 1;
          const last = first + 6;

          const firstday = dayjs(curr.setDate(first)).format('YYYY-MM-DD');
          const lastday = dayjs(curr.setDate(last)).format('YYYY-MM-DD');

          state.dateRange = [firstday, lastday];
          break;
        }
        case 1: {
          const curr = new Date();
          const firstday = dayjs(new Date(curr.getFullYear(), curr.getMonth(), 1)).format('YYYY-MM-DD');
          const lastday = dayjs(new Date(curr.getFullYear(), curr.getMonth() + 1, 0)).format('YYYY-MM-DD');

          state.dateRange = [firstday, lastday];
          break;
        }
        case 2: {
          const curr = new Date();
          const firstday = dayjs(new Date(curr.getFullYear(), 0, 1)).format('YYYY-MM-DD');
          const lastday = dayjs(new Date(curr.getFullYear(), 11, 31)).format('YYYY-MM-DD');

          state.dateRange = [firstday, lastday];
          break;
        }
        default: {
          break;
        }
      }
    },

    setSwitcher: (state, payload) => {
      state.switcher = payload;
    },

    updateDateRange: (state, payload) => {
      state.dateRange = payload;
    },

    updateDateFrom: (state, payload) => {
      state.dateRange[0] = payload;
    },

    updateDateTo: (state, payload) => {
      state.dateRange[1] = payload;
    },

    updatePrinterSelectedOption: (state, payload) => {
      if (state.selects.printer.options.includes(payload)) state.selects.printer.selectedOption = payload;
      else state.selects.printer.selectedOption = '';
    },

    setPrinterOptions: (state, payload) => {
      state.selects.printer.options = [...new Set(payload)];
      if (!state.selects.printer.options.includes(state.selects.printer.selectedOption)) {
        state.selects.printer.selectedOption = '';
      }
    },

    updateComputerSelectedOption: (state, payload) => { state.selects.computer.selectedOption = payload; },

    setComputerOptions: (state, payload) => {
      state.selects.computer.options = [...new Set(payload)];
      if (!state.selects.computer.options.includes(state.selects.computer.selectedOption)) {
        state.selects.computer.selectedOption = '';
      }
    },

    setIsTableGroupedByDates: (state, payload) => {
      state.isTableGroupedByDates = payload;
    },

    setIsWorktimeOnly: (state, payload) => {
      state.isWorktimeOnly = payload;
    },

    updateSelectedApps: (state, payload) => {
      state.selectedApps = payload;
    },
  },
};
