<template>
  <div :class="$style.pageContainer">
    <router-view />
    <portal-target name="modal" />
  </div>
</template>

<script>
import { PortalTarget } from 'portal-vue';
import { getNewTasks } from '@/api/methods/tasks';
import Vue from 'vue';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';

Vue.use(VueToast);

export default {
  components: {
    PortalTarget,
  },
  data: () => ({
    interval: null,
  }),
  async created() {
    await this.getNotifications();
    this.interval = setInterval(this.getNotifications, 2000);
  },
  methods: {
    async getNotifications() {
      const result = await getNewTasks();
      if (result !== 'error' && result === true) {
        Vue.$toast.open({
          message: 'Новые события нарушения безопасности!',
          type: 'info',
          duration: 5000,
          onClick: this.openNotifications,
        });

        this.$store.commit('navigation/setNewNotifications', true);
      }
    },
    openNotifications() {
      const routeData = this.$router.resolve({
        name: 'notifications',
      });
      window.open(routeData.href, '_blank');
    },
  },
};
</script>
<style lang="scss" module>
.pageContainer {
  height: 100%;
}
</style>
<style>
.v-toast__item--info {
  background-color: #2985BF;
}
</style>
