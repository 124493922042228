import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store/index';

import loadQueryParametersFromStore from './middlewares/storeParameters';
import authenticated from './middlewares/authenticated';
import guest from './middlewares/guest';
import log from './middlewares/log';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      name: 'index',
      path: '/',
      component: () => import('../views/index.vue'),
      meta: {
        middleware: authenticated,
      },
    },
    {
      name: 'noAccess',
      path: '/accessDenied',
      component: () => import('../views/accessDenied.vue'),
      meta: {
        middleware: authenticated,
      },
    },
    {
      name: 'reports',
      path: '/reports',
      component: () => import('../views/reports/index.vue'),
      meta: {
        middleware: authenticated,
      },
      children: [{
        name: 'printers',
        path: 'printers',
        component: () => import('../views/reports/printers/index.vue'),
        meta: {
          middleware: authenticated,
        },
        children: [
          {
            name: 'printer-statistics',
            path: 'printer-statistics',
            redirect: { name: 'docStat' },
            component: () => import('../views/reports/printers/statistics/index.vue'),
            meta: {
              middleware: [
                authenticated,
              ],
            },
            children: [
              {
                name: 'docStat',
                path: 'docStat',
                component: () => import('../views/reports/printers/statistics/documents/doc-stat.vue'),
                meta: {
                  middleware: [
                    authenticated,
                    loadQueryParametersFromStore,
                  ],
                  requiredStoreParameters: {
                    dateRange: true,
                    printer: true,
                    tableGroup: true,
                    export: true,
                  },
                },
              },
              {
                name: 'printStat',
                path: 'printStat',
                component: () => import('../views/reports/printers/statistics/print-stat.vue'),
                meta: {
                  middleware: [
                    authenticated,
                    loadQueryParametersFromStore,
                  ],
                  requiredStoreParameters: {
                    dateRange: true,
                    printer: true,
                  },
                },
              },
              {
                name: 'userStat',
                path: 'userStat',
                component: () => import('../views/reports/printers/statistics/user-stat.vue'),
                meta: {
                  middleware: [
                    authenticated,
                    loadQueryParametersFromStore,
                  ],
                  requiredStoreParameters: {
                    dateRange: true,
                  },
                },
              },
            ],
          },
          {
            name: 'employees',
            path: 'employees',
            redirect: { name: 'employeeReport' },
            components: {
              default: () => import('../views/reports/printers/employees/index.vue'),
            },
            meta: {
              middleware: [
                authenticated,
              ],
            },
            children: [
              {
                name: 'employeeReport',
                path: 'employeeReport',
                component: () => import('../views/reports/printers/employees/employee-report.vue'),
                meta: {
                  middleware: [
                    authenticated,
                    loadQueryParametersFromStore,
                  ],
                  requiredStoreParameters: {
                    dateRange: true,
                    tableGroup: true,
                    export: true,
                  },
                },
              },
              {
                name: 'employee-print-report',
                path: 'employee-print-report',
                component: () => import('../views/reports/printers/statistics/user-stat.vue'),
                meta: {
                  middleware: [
                    authenticated,
                    loadQueryParametersFromStore,
                  ],
                  requiredStoreParameters: {
                    dateRange: true,
                  },
                },
              },
            ],
          },
          {
            name: 'print',
            path: 'print',
            redirect: { name: 'printerReport' },
            components: {
              default: () => import('../views/reports/printers/printers/index.vue'),
            },
            meta: {
              middleware: [
                authenticated,
              ],
            },
            children: [
              {
                name: 'printerReport',
                path: 'printerReport',
                component: () => import('../views/reports/printers/printers/printer-report.vue'),
                meta: {
                  middleware: [
                    authenticated,
                    loadQueryParametersFromStore,
                  ],
                  requiredStoreParameters: {
                    dateRange: true,
                    printer: true,
                    tableGroup: true,
                    export: true,
                  },
                },
              },
              {
                name: 'printer-print-report',
                path: 'printer-print-report',
                component: () => import('../views/reports/printers/statistics/print-stat.vue'),
                meta: {
                  middleware: [
                    authenticated,
                    loadQueryParametersFromStore,
                  ],
                  requiredStoreParameters: {
                    dateRange: true,
                    printer: true,
                  },
                },
              },
            ],

          },
        ],
      },
      {
        name: 'users',
        path: 'users',
        component: () => import('../views/reports/users/index.vue'),
        meta: {
          middleware: authenticated,
        },

        children: [
          {
            name: 'consolidated',
            path: 'consolidated',
            component: () => import('../views/reports/users/consolidated/consolidated-report.vue'),
            meta: {
              middleware: [
                authenticated,
                loadQueryParametersFromStore,
              ],
              requiredStoreParameters: {
                dateRange: true,
                multiSelect: true,
                worktimeOnly: true,
                export: true,
              },
            },
          },
          {
            name: 'worktime',
            path: 'worktime',
            redirect: { name: 'heatMap' },
            component: () => import('../views/reports/users/worktime/index.vue'),
            meta: {
              middleware: [
                authenticated,
                loadQueryParametersFromStore,
              ],
              requiredStoreParameters: {
                dateRange: true,
                multiSelect: true,
                worktimeOnly: true,
                export: true,
              },
            },
            children: [
              {
                name: 'worktimeDetails',
                path: 'worktimeDetails',
                component: () => import('../views/reports/users/worktime/worktime-report.vue'),
                meta: {
                  middleware: [
                    authenticated,
                    loadQueryParametersFromStore,
                  ],
                  requiredStoreParameters: {
                    dateRange: true,
                    multiSelect: true,
                    worktimeOnly: true,
                    export: true,
                  },
                },
              },
              {
                name: 'heatMap',
                path: 'heatMap',
                component: () => import('../views/reports/users/worktime/heat-map.vue'),
                meta: {
                  middleware: [
                    authenticated,
                    loadQueryParametersFromStore,
                  ],
                  requiredStoreParameters: {
                    dateRange: true,
                    multiSelect: true,
                    worktimeOnly: true,
                    switcher: true,
                    dateSelect: true,
                  },
                },
              },
              {
                name: 'disciplineViolations',
                path: 'disciplineViolations',
                component: () => import('../views/reports/users/worktime/discipline-violations.vue'),
                meta: {
                  middleware: [
                    authenticated,
                    loadQueryParametersFromStore,
                  ],
                  requiredStoreParameters: {
                    dateRange: true,
                    multiSelect: true,
                    worktimeOnly: true,
                    dateSelect: true,
                  },
                },
              },
            ],
          },
          {
            name: 'activityFeed',
            path: 'activityFeed',
            component: () => import('../views/reports/users/activity/activity-feed.vue'),
            meta: {
              middleware: [
                authenticated,
                loadQueryParametersFromStore,
              ],
              requiredStoreParameters: {
                dateRange: true,
                multiSelect: true,
                export: true,
              },
            },
          },
          {
            name: 'productivity',
            path: 'productivity',
            component: () => import('../views/reports/users/productivity/productivity-report.vue'),
            meta: {
              middleware: [
                authenticated,
                loadQueryParametersFromStore,
              ],
              requiredStoreParameters: {
                dateRange: true,
                multiSelect: true,
                worktimeOnly: true,
                export: true,
              },
            },
          },
          {
            name: 'appsTime',
            path: 'appsTime',
            component: () => import('../views/reports/users/appsTime/apps-time-report.vue'),
            meta: {
              middleware: [
                authenticated,
                loadQueryParametersFromStore,
              ],
              requiredStoreParameters: {
                dateRange: true,
                multiSelect: true,
                worktimeOnly: true,
                treeSelect: true,
                export: true,
              },
            },
          },
          {
            name: 'programs',
            path: 'programs',
            component: () => import('../views/reports/statistics/programs/program-stat.vue'),
            meta: {
              middleware: [
                authenticated,
                loadQueryParametersFromStore,
              ],
              requiredStoreParameters: {
                dateRange: true,
                multiSelect: true,
                export: true,
              },
            },
          },
        ],
      },
      {
        name: 'internet',
        path: 'internet',
        component: () => import('../views/reports/internet/index.vue'),
        meta: {
          middleware: authenticated,
        },
        children: [
          {
            name: 'websites',
            path: 'websites',
            component: () => import('../views/reports/statistics/websites/website-stat.vue'),
            meta: {
              middleware: [
                authenticated,
                loadQueryParametersFromStore,
              ],
              requiredStoreParameters: {
                dateRange: true,
                multiSelect: true,
                export: true,
              },
            },
          },
          {
            name: 'messengers',
            path: 'messengers',
            component: () => import('../views/reports/internet/messengers.vue'),
            meta: {
              middleware: [
                authenticated,
                loadQueryParametersFromStore,
              ],
              requiredStoreParameters: {
                dateRange: true,
                multiSelect: true,
                export: true,
              },
            },
          },
          {
            name: 'mail',
            path: 'mail',
            component: () => import('../views/reports/internet/mail.vue'),
            meta: {
              middleware: [
                authenticated,
                loadQueryParametersFromStore,
              ],
              requiredStoreParameters: {
                dateRange: true,
                multiSelect: true,
                export: true,
              },
            },
          },
          {
            name: 'detailsMessengers',
            path: 'detailsMessengers',
            component: () => import('../views/reports/internet/details-messengers.vue'),
            meta: {
              middleware: [
                authenticated,
                loadQueryParametersFromStore,
              ],
              requiredStoreParameters: {
                dateRange: true,
                multiSelect: true,
                export: true,
              },
            },
          },
        ],
      },
      {
        name: 'microphone',
        path: 'microphone',
        component: () => import('../views/reports/microphone/index.vue'),
        meta: {
          middleware: authenticated,
        },
        children: [
          {
            name: 'microphoneSum',
            path: 'microphoneSum',
            component: () => import('../views/reports/microphone/sum.vue'),
            meta: {
              middleware: [
                authenticated,
                loadQueryParametersFromStore,
              ],
              requiredStoreParameters: {
                dateRange: true,
                multiSelect: true,
                worktimeOnly: true,
                export: true,
              },
            },
          },
          {
            name: 'microphoneDetails',
            path: 'microphoneDetails',
            component: () => import('../views/reports/microphone/details.vue'),
            meta: {
              middleware: [
                authenticated,
                loadQueryParametersFromStore,
              ],
              requiredStoreParameters: {
                dateRange: true,
                multiSelect: true,
                worktimeOnly: true,
                export: true,
              },
            },
          },
        ],
      },
      ],
    },
    {
      name: 'user-login',
      path: '/user/login',
      component: () => import('../views/user/login.vue'),
      meta: {
        middleware: guest,
      },
    },
    {
      name: 'security',
      path: 'security',
      component: () => import('../views/index.vue'),
      meta: {
        middleware: authenticated,
      },
    },
    {
      name: 'notifications',
      path: '/notifications',
      component: () => import('../views/notifications/index.vue'),
      meta: {
        middleware: authenticated,
      },
    },
    {
      name: 'search',
      path: '/search',
      component: () => import('../views/search/index.vue'),
      meta: {
        middleware: authenticated,
      },
    },
    {
      name: 'remoteViewer',
      path: '/remoteViewer',
      component: () => import('../views/vnc/index.vue'),
      meta: {
        middleware: authenticated,
      },
    },
    {
      name: 'fullScreenRemoteViewer',
      path: '/fullScreenRemoteViewer/:compId',
      component: () => import('../views/vnc/fullScreen.vue'),
      meta: {
        middleware: authenticated,
      },
    },
    {
      name: 'tasks',
      path: '/tasks',
      component: () => import('../views/tasks/index.vue'),
      meta: {
        middleware: authenticated,
      },
    },
    {
      name: 'computers',
      path: '/computers',
      component: () => import('../views/computers/index.vue'),
      meta: {
        middleware: authenticated,
      },
      children: [{
        name: 'computers-computer',
        path: ':computer',
        components: {
          default: () => import('../views/computers/computer/index-default.vue'),
          control: () => import('../views/computers/computer/index-control.vue'),
        },
        meta: {
          middleware: authenticated,
        },
        children: [
          {
            name: 'computers-computer-log-record',
            path: ':log/:record?',
            components: {
              default: () => import('../views/computers/computer/log-record/index-default.vue'),
              control: () => import('../views/computers/computer/log-record/index-control.vue'),
            },
            meta: {
              middleware: [
                authenticated,
                log,
              ],
            },
          }],
      }],
    },
    {
      name: 'advanced',
      path: '/advanced',
      component: () => import('../views/computers/advanced/index.vue'),
      meta: {
        middleware: authenticated,
      },
    },
    {
      name: 'settings',
      path: '/settings',
      component: () => import('../views/settings/index.vue'),
      meta: {
        middleware: authenticated,
      },
      children: [
        {
          name: 'general',
          path: 'general',
          component: () => import('../views/settings/general/generalSettings.vue'),
          meta: {
            middleware: authenticated,
            requiredStoreParameters: {
            },
          },
        },
        {
          name: 'agents',
          path: 'agents',
          component: () => import('../views/settings/agents/agents.vue'),
          meta: {
            middleware: [
              authenticated,
            ],
            requiredStoreParameters: {
              generalSettings: true,
            },
          },
          children: [
            {
              name: 'agents-agent',
              path: ':type/:computer',
              component: () => import('../views/settings/agents/agent/index.vue'),
              meta: {
                middleware: authenticated,
                requiredStoreParameters: {
                  generalSettings: true,
                },
              },
              children: [
                {
                  name: 'agents-agent-settings',
                  path: 'settings/:log',
                  component: () => import('../views/settings/agents/agent/settings/index.vue'),
                  meta: {
                    middleware: [
                      authenticated,
                    ],
                    requiredStoreParameters: {
                      generalSettings: true,
                    },
                  },
                },
                {
                  name: 'agents-agent-security',
                  path: 'security/:log',
                  component: () => import('../views/settings/agents/agent/security/index.vue'),
                  meta: {
                    middleware: [
                      authenticated,
                    ],
                    requiredStoreParameters: {
                      generalSettings: true,
                    },
                  },
                },
                {
                  name: 'agents-agent-notifications',
                  path: 'notifications/:log',
                  component: () => import('../views/settings/agents/agent/notifications/index.vue'),
                  meta: {
                    middleware: [
                      authenticated,
                    ],
                    requiredStoreParameters: {
                      generalSettings: true,
                    },
                  },
                },
                {
                  name: 'agents-agent-irregular',
                  path: ':log',
                  component: () => import('../views/settings/agents/agent/notifications/irregular.vue'),
                  meta: {
                    middleware: [
                      authenticated,
                    ],
                    requiredStoreParameters: {
                      generalSettings: true,
                    },
                  },
                },
              ],
            },
          ],
        },
        {
          name: 'controls',
          path: 'controls',
          component: () => import('../views/settings/control/control.vue'),
          meta: {
            middleware: authenticated,
            requiredStoreParameters: {
            },
          },
          children: [
            {
              name: 'controls-control',
              path: ':user',
              component: () => import('../views/settings/control/controlParameters.vue'),
              meta: {
                middleware: authenticated,
                requiredStoreParameters: {
                },
              },
            },
          ],
        },
        {
          name: 'category',
          path: 'category',
          component: () => import('../views/settings/categories/categories.vue'),
          meta: {
            middleware: authenticated,
            requiredStoreParameters: {
            },
          },
        },
        {
          name: 'printPrice',
          path: 'printPrice',
          component: () => import('../views/settings/printPrice/printPrice.vue'),
          meta: {
            middleware: authenticated,
            requiredStoreParameters: {
            },
          },
        },
      ],
    },
  ],
});

// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters);
    // Then run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to,
      store,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  }

  return next();
});

export default router;
