import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import user from './modules/user';
import historyLogs from './modules/history-logs';
import printScreenshots from './modules/print-screenshots';
import logs from './modules/logs/index';
import filters from './modules/filters';
import pageSpecificData from './modules/page-specific-data';
import settings from './modules/settings';
import exportData from './modules/export-data';
import loader from './modules/loader';
import navigation from './modules/navigation';
import vnc from './modules/vnc';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    historyLogs,
    printScreenshots,
    logs,
    filters,
    pageSpecificData,
    settings,
    exportData,
    loader,
    navigation,
    vnc,
  },
  plugins: [createPersistedState({
    paths: ['historyLogs'],
  })],
  actions: {
    init: (store) => store.dispatch('user/init'),
  },
});
