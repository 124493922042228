import { getInfo, login, logout } from '@/api/methods/user';
import { logTypes } from '@/assets/scripts/dictionaries';

export default {
  namespaced: true,
  state: () => ({
    id: 0,
    authenticated: false,
    role: null, // TODO: rename to roleId
    allowedTypes: [], // TODO: rename to logTypes
    logsToShow: undefined,
    accessMap: {},
    buildVersion: 0,
    telegramAuthNeeded: false,
  }),
  getters: {
    buildVersion: (state) => state.buildVersion,
    logsToShow: (state) => state.logsToShow,
    telegramAuthNeeded: (state) => state.telegramAuthNeeded,
    hasAccess: (state) => (path) => {
      let current = state.accessMap;
      const pathArray = path.split('/');

      for (let i = 1; i < pathArray.length; i++) {
        if (current[pathArray[i]]) current = current[pathArray[i]];
        else break;
      }
      // eslint-disable-next-line no-underscore-dangle
      if (current._roles.includes(state.role)) return true;
      return false;
    },
    canEdit: ({ role }) => [1, 3, 4].includes(role),
    // TODO: Перенос данных на бекенд. Геттер станет ненужным
    allowedTypes: (state) => {
      if (state.role === 1) {
        return logTypes;
      }

      return logTypes.filter((i) => state.allowedTypes.includes(i.id));
    },
    isTypeAllowed: (state) => (slug) => (state.role === 1)
      || logTypes.findIndex((i) => i.slug === slug) !== -1,
  },
  mutations: {
    isAuthCodeNeeded: (state, payload) => {
      state.telegramAuthNeeded = payload;
    },
    setId: (state, payload) => {
      state.id = payload;
    },
    setAuthenticated: (state, payload) => {
      state.authenticated = payload;
    },
    setRole: (state, payload) => {
      state.role = payload;
    },
    setAllowedTypes: (state, payload) => {
      state.allowedTypes = payload;
    },
    setLogsToShow: (state, payload) => {
      state.logsToShow = payload;
    },
    setAccessMap: (state, payload) => {
      state.accessMap = payload;
    },
    setBuildVersion: (state, payload) => {
      state.buildVersion = payload;
    },
  },
  actions: {
    init: (store) => store.dispatch('getInfo').catch(() => store.dispatch('logout')),
    getInfo: (store) => getInfo().then(({ id, allowedTypes, role, accessMap, buildVersion }) => {
      store.commit('setBuildVersion', buildVersion);
      store.commit('setId', id);
      store.commit('setAuthenticated', true);
      store.commit('setRole', role);
      store.commit('setAllowedTypes', allowedTypes);
      store.commit('setAccessMap', accessMap);
    }),
    login: (store, { username, password, authCode }) => login(username, password, authCode)
      .then(({ id, allowedTypes, role, accessMap, tgCodeNeeded }) => {
        if (tgCodeNeeded) store.commit('isAuthCodeNeeded', true);
        else {
          store.commit('isAuthCodeNeeded', false);
          store.commit('setId', id);
          store.commit('setAuthenticated', true);
          store.commit('setRole', role);
          store.commit('setAllowedTypes', allowedTypes);
          store.commit('setAccessMap', accessMap);
        }
      }),
    logout: (store) => logout().then(() => {
      store.commit('setId', 0);
      store.commit('setAuthenticated', false);
      store.commit('setRole', null);
      store.commit('setAllowedTypes', []);
    }),
  },
};
